<template>
  <div
    class="modal inmodal"
    id="viewMenu"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content animated fadeIn">
        <div class="modal-header">
          <h4 >Menu Privileges</h4>
          <hr />
          <div class="row">
            <div class="col-lg-6" >
              <div :id="jsTreeId" class="jstree-default"></div>
            </div>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-white"
            data-dismiss="modal"
            id="closeBtn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from "../../../framework/plugin/Utils";
export default {
  props: {
    data: Object,
  },
  data() {
    return {
      jsTreeId: "using_json",
      isValid: false,
    };
  },
  watch: {
    data() {
      var self = this;
      if (this.isValid) {
        Utils.jsTreeDestroy(this.jsTreeId);
      }
      Utils.jsTreeView(Utils.jsTreeMenuDataCreate(this.data), this.jsTreeId);
      this.isValid = true;
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 268px;
  margin: 1.75rem auto;
}
</style>
