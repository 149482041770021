<template>
  <div class="wrapper wrapper-content animated fadeInRight">
    <div class="row">
      <div class="col-lg-12">
        <div class="ibox">
          <div class="ibox-title">
            <h5></h5>
            <div class="ibox-tools">
              <router-link to="addMfaUser">
                <i class="fa fa-plus"></i> &nbsp;&nbsp; Add
              </router-link>
            </div>
          </div>
          <div class="ibox-content">
            <div class="table-responsive">
              <table class="table table-bordered table-hover" :class="dataTablesMfa">
                <thead>
                  <tr>
                    <th>{{ mfaColumn.id }}</th>
                    <th>{{ mfaColumn.userId }}</th>
                    <th>{{ mfaColumn.userkey }}</th>
                    <!--<th>{{ mfaColumn.ismfaenabled }}</th>-->
                    <th>{{ mfaColumn.incorrattempts }}</th>
                    <th>{{ mfaColumn.isactive }}</th>
                    <th>{{ mfaColumn.action }}</th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="gradeX" v-for="(value, key) in mfaData" :key="key">
                    <td style="width: 8%">{{ value.id }}</td>
                    <td style="width: 25%">{{ value.userID }}</td>
                    <td style="width: 28%">{{ value.userKey }}</td>
                    <!--<td style="width: 28%">{{ value.isMFAenabled }}</td>-->
                   <td style="width: 28%">{{ value.incorrAttempts }}</td>
                   <td style="width: 28%">{{ value.isActive }}</td>
                    <td style="width: 2%">
                      <i class="fas fa-user-cog"></i>
                      <a class="dropdown-toggle" data-toggle="dropdown" href="#">
                        <i class="fa fa-wrench"></i>
                      </a>
                      <ul class="dropdown-menu dropdown-user">
                        <li>
                          <router-link
                            :to="{
                              path: '/updateMfaUser',
                              query: { id: value.id },
                            }"
                            class="dropdown-item"
                            >Edit</router-link
                          >
                        </li>
                        <li>
                          <a
                            class="dropdown-item"
                            data-toggle="modal"
                            data-target="#myModal4"
                            @click="addModalData(value)"
                            >Delete</a
                          >
                        </li>
                      </ul>
                    </td>
                  </tr>
                </tbody>
                <tfoot>
                  <tr>
                    <th>{{ mfaColumn.id }}</th>
                    <th>{{ mfaColumn.userId }}</th>
                    <th>{{ mfaColumn.userkey }}</th>
                    <!--<th>{{ mfaColumn.ismfaenabled }}</th>-->
                    <th>{{ mfaColumn.incorrattempts }}</th>
                    <th>{{ mfaColumn.isactive }}</th>
                    <th>{{ mfaColumn.action }}</th>
                  </tr>
                </tfoot>
              </table>
            </div>
            <DeleteModal :data="data"></DeleteModal>
            <MenuViewModal :data="menuData"></MenuViewModal>
            <ApiViewModel :data="apiData"></ApiViewModel>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import AxiosService from "../plugin/AxiosService.js";
import Constant from "../plugin/Constant.js";
import DataTableService from "../plugin/DataTableService";
import DeleteModal from "../components/common/DeleteModal";
import EventBus from "../plugin/event-bus";
import MenuViewModal from "../components/common/MenuViewModal";
import ApiViewModel from "../components/common/ApiViewModel";
import AuditListner from "../plugin/AuditListner";

export default {
  data() {
    return {
      mfaColumn: {
        id: "Id",
        userId: "User Id",
        userkey: "User Key",
        ismfaenabled: "MFA Enabled",
        incorrattempts: "Incorrect Attempts",
        isactive: "Active",
        action: "Action"
      },
      mfaData: [],
      menuData: {},
      apiData: [],
      dataTablesMfa: "dataTables-Mfa",
      exportTitle: "MFA Details",
      data: {
        title: "MFA",
        name: "",
        id: 0,
        url: Constant.url.MFA_USER_DELETE_URL,
      },
      selectedmfaData: {},
    }; 
  },
  components: {
    DeleteModal,
    MenuViewModal,
    ApiViewModel,
  },
  created: function () {
    this.fetchmfaData();
    this.deleteEvent();
  },
  mounted: function () {
  },
  methods: {
    fetchmfaData: function () {
      var self = this;
      var responseCode = Constant.data.RESPONSE_CODE;
      AxiosService.get(Constant.url.MFA_USER_GET_ALL_URL).then(async (result) => {
          for(let i = 0; i < result.length; i++) {
              result[i].isMFAenabled = result[i].isMFAenabled === 1 ? true : false;
              result[i].isActive = result[i].isActive === 1 ? true : false;
          }
        self.mfaData = result;
        await DataTableService.loadDataTable(this.dataTablesMfa, this.exportTitle);
      });
    },
    addModalData: function (value) {
      this.selectedmfaData = value;
      this.data.name = value.userID;
      this.data.id = value.id;
    },
    addMenuModaldata: function (value) {
      this.menuData = value;
    },
    addApiModaldata: function (value) {
      this.apiData = value;
    },
    deleteEvent: function () {
      const self = this;
      EventBus.$on(Constant.data.DELETE_EVENT, (data) => {
        if (data.responseCode == Constant.data.SUCCESS) {
          AuditListner.addAuditLog(
            AuditListner.initalizedData(
              Constant.data.MFA_ACTIVITY,
              Constant.data.DELETE_TITLE,
              AuditListner.roleTemplate(self.selectedmfaData, "", [], [])
            )
          );
          self.fetchmfaData();
          DataTableService.reLoadDataTable(self.dataTablesMfa);
          DataTableService.loadDataTable(self.dataTablesMfa, self.exportTitle);
        }
      });
    },
  },
  beforeDestroy() {
    EventBus.$off(Constant.data.DELETE_EVENT);
  },
};
</script>
<style scoped>
.ibox-tools a {
  cursor: pointer;
  margin-left: 5px;
  color: #0f0f0f !important;
}
a {
  color: #636363;
  text-decoration: none;
  background-color: transparent;
  -webkit-text-decoration-skip: objects;
}
.view {
  color: #1c84c6f7;
}
</style>
