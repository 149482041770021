<template>
  <div
    class="modal inmodal"
    id="viewApi"
    tabindex="-1"
    role="dialog"
    aria-hidden="true"
  >
    <div class="modal-dialog">
      <div class="modal-content animated fadeIn">
        <div class="modal-header">
          <h4>API Privileges</h4>
          <hr />
          <div class="row">
            <div class="col-lg-6"></div>
          </div>

          <div class="table-responsive">
            <table
              class="table table-bordered table-hover"
              :class="dataTablesApi"
            >
              <thead>
                <tr>
                  <th>{{ apiColumn.id }}</th>
                  <th>{{ apiColumn.name }}</th>
                  <th>{{ apiColumn.type }}</th>
                  <th>{{ apiColumn.description }}</th>
                  <th>{{ apiColumn.method }}</th>
                </tr>
              </thead>
              <tbody>
                <tr class="gradeX" v-for="(value, key) in data" :key="key">
                  <td style="width: 8%;">{{ value.id }}</td>
                  <td style="width: 28%;">{{ value.name }}</td>
                  <td style="width: 7%;">{{ value.type }}</td>
                  <td style="width: 55%;">{{ value.description }}</td>
                  <td style="width: 28%;">{{ value.method }}</td>
                </tr>
              </tbody>
              <tfoot>
                <tr>
                  <th>{{ apiColumn.id }}</th>
                  <th>{{ apiColumn.name }}</th>
                  <th>{{ apiColumn.type }}</th>
                  <th>{{ apiColumn.description }}</th>
                  <th>{{ apiColumn.method }}</th>
                </tr>
              </tfoot>
            </table>
          </div>
        </div>
        <div class="modal-footer">
          <button
            type="button"
            class="btn btn-white"
            data-dismiss="modal"
            id="closeBtn"
          >
            Close
          </button>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Utils from "../../../framework/plugin/Utils";
import DataTableService from "../../../framework/plugin/DataTableService";
import Constant from '../../plugin/Constant';

export default {
  props: {
    data: Array,
  },
  data() {
    return {
      apiColumn: {
        id: "Id",
        name: "Name",
        type: "Type",
        description: "Description",
        method: "Method",
      },
      dataTablesApi: "apiDataTable",
    };
  },
  created: function() {},
  watch: {
    data() {
      DataTableService.reLoadDataTable(this.dataTablesApi);
      setTimeout(() => {
        DataTableService.loadDataTableWithoutExport(
          this.dataTablesApi,
          "",
          false
        );
      }, Constant.timeout);
    },
  },
};
</script>
<style scoped>
.modal-dialog {
  max-width: 800px;
  margin: 1.75rem auto;
}</style
>>
